<div class="mt-5">
  <section>
    <div class="mx-auto max-w-screen-2xl px-4 lg:px-12">
        <div class="dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div class="border-b dark:border-gray-700 mx-4">
                <div class="flex items-center justify-between space-x-4 pt-3">
                    <div class="flex-1 flex items-center space-x-3">
                        <h5 class="dark:text-white font-semibold">Codigos a personas</h5>
                    </div>
                </div>


                <div class="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
                    <div class="w-full lg:w-2/3 flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
                        <form class="w-full md:max-w-sm flex-1 md:mr-4">
                            <label for="default-search" class="text-sm font-medium text-gray-900 sr-only dark:text-white">Buscar</label>
                            <div class="relative">
                              <input type="search" id="default-search" class="block w-full p-2 pl-10 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Buscar códigos..."
                              (click)="searchOpen()"
                              (input)="searchCode($event)">
                              <ul class="border-gray-200 rounded-lg shadow overflow-y-auto max-h-60 z-20 overflow-x-auto" style="max-height: 80px;" *ngIf="openSearch">
                                  <li
                                    class="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                                    *ngFor="let item of filteredCodesList"
                                    [ngClass]="{ 'bg-gray-300': selectedItem === item}"
                                    (click)="buttonOpen(item)"
                                  >
                                    <span>
                                      {{item.code}}
                                    </span>
                                  </li>
                                </ul>
                          </div>
                        </form>

                    </div>
                    <div class="w-full md:w-auto flex flex-col md:flex-row mb-3 md:mb-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0" *ngIf="selectedItem">
                        <button type="button" (click)="addCode(selectedItem)" class="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                          <i class="fas fa-plus mr-2"></i> Agregar
                        </button>
                    </div>
                </div>
            </div>

            <div class="overflow-x-auto">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-3">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-40 py-3">Código</th>
                      <th scope="col" class="px-40 py-3">Nombre</th>
                      <th scope="col" class="px-40 py-3">Acción</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of personDinamicCode">
                    <tr class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                      <th scope="row" class="px-40 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <span class="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                          {{item?.code}}
                        </span>
                      </th>
                      <td class="pl-40">
                        <span>{{item?.name}}</span>
                      </td>
                      <td class="pl-5">
                        <button class="ml-[155px] bg-red-600 w-5 h-5 rounded-md" (click)="deleteCodePerson(item?.code)" >
                          <i class="fas fa-trash-alt text-white"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span class="text-black pl-10" *ngIf="personDinamicCode?.length === 0 && !loading">No se encontraron códigos agregados</span>
                <span class="text-black pl-10" *ngIf="loading">Cargando...</span>

                <!-- <div *ngIf="loading" class="loading-indicator">
                  <i class="fas fa-spinner fa-spin"></i>
              </div> -->
            </div>
            <nav class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
                  <span>"Busca los códigos y agrégalos."</span>
                </span>

            </nav>
        </div>
    </div>
</section>

</div>
