

import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';



mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};

const now = new Date();

//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ new Date() ,new Date()];

@Component({
  selector: 'app-financial-payments',
  templateUrl: './financial-payments.component.html',
  styleUrls: ['./financial-payments.component.css'],
  providers: [DatePipe]

})
export class FinancialPaymentsComponent implements OnInit {

  type:any = 'f';

  filterData:any;
  queryParams: any;
  person: any;
  section_transactions: any = [];
  payment_base: any = [];

  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading_stadistic = false;

  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   rows: any = [];
   installments: any = [];
   installmentNexPayment :any;
    view = 1;
    invoiceSelected:any;
    installMentSelected:any;
    payment:any;
    stadistic:any;
    rows_temp:any = [];


 range: Array < Date > = week;
 date: Array < Date > = [];
 nonForm: Array < Date > = [];
 external = week;
 d1:any;
 d2:any; 


 dateSetting3: MbscDatetimeOptions = {
   lang: 'es',
   theme: 'ios',
   themeVariant: 'light',
   max:now,
   responsive: {
     small: {
         controls: ['calendar'],
         display: 'center'
     },
     medium: {
         display: 'bubble',
     }
 }
 
 };

 birthday = new Date();
date_base: any = new Date;
date_base_string :any;

mobileSettings: MbscDatetimeOptions = {
 display: 'bottom',
 themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
 display: 'bubble',
 touchUi: false
};

search_form:any =  '';

loadingXls:any;
shop:any;

shops:any;

page = 1; // Current page number
limit = 100; // Number of documents per page
params_payment:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private offcanvasService: NgbOffcanvas



  ) { }

  ngOnInit(): void {
    this.range = week;


    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');


    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
        this.shop = this.queryParams?.shop || '' ;


        this.filterData = {f:new Date(),status:'',shop:this.shop};


    });

    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    this.loading_person = true;

    this.getListInvoiceCredits();

    this.getStadistic();

    this.getshops();

    
  }


  getStadisticServer = async () => {
    try{
       let query ={
        init:this.d1,
        end:this.d2
       }

        return this._user.getstadistic_invice_status(query);

      } catch (error) {
        console.error(error);
        
        
      }
  }


  getListInvoiceCredits = async () => {
    try {
      
      this.loading = true;

      const resp = await this.listInvoiceCredits();
      let result = resp?.res;

      let rows:any = this.rows;
      this.rows =rows.concat(result);


    } catch (error) {
      console.error(error);

    } 

    this.loading = false;

  }


  
  getStadistic = async () => {
    try {
      this.loading_stadistic = true;


      const resp = await this.getStadisticServer();

      let stadistic = resp?.res;

          
      if(this.shop != ''){

        this.stadistic = {

          complete : stadistic?.segment?.complete[this.shop] || 0,
          pending :stadistic?.segment?.pending[this.shop] || 0,
          trash : stadistic?.segment?.trash[this.shop] || 0,
          total: ((stadistic?.segment?.complete[this.shop] || 0) + (stadistic?.segment?.pending[this.shop] || 0) + (stadistic?.segment?.trash[this.shop] || 0) )
         
        }; 

      
      }else{

         
      this.stadistic = {

        complete : stadistic?.total?.complete || 0,
        pending :stadistic?.total?.pending || 0,
        trash : stadistic?.total?.trash || 0,
        total : ((stadistic?.total?.trash || 0) + (stadistic?.total?.complete|| 0) + (stadistic?.total?.pending || 0))

      }; 

      }


    } catch (error) {
      console.error(error);



    } 
    this.loading_stadistic = false;

   


  }


  listInvoiceCredits = async () => {

    try{
      let request = { 
        
        page:this.page,
        limit:this.limit,
        init:this.d1,
        end:this.d2,
        identifier:this.search_form?.trim() != ''?this.search_form?.trim():''
      
      }

      debugger
        return this._user.listInvoiceCredits(this.filterData,request);

      } catch (error) {
        console.error(error);
      }

  }



  getPersonInstallmentsInvoiceCredits = async (invoice:any) => {
    this.loading = true;

    this.invoiceSelected = invoice;
    try {
         const data =  {
          code_invoice:invoice?.code 
         
         }
       const  installments  = await this._user.getPersonInstallmentsInvoiceCredits(data);

       this.installments = installments?.res;

       this.installments.map((value:any, index:any) => {
        if(value?.installMent?.payment_status?.code != 'PaymentComplete' && !this.installmentNexPayment){

          this.installmentNexPayment = value?.installMent?.code;

        }

        value["totalpaymentReference"] = 0;

        value?.paymentReference?.forEach((value2:any) =>{
          value["totalpaymentReference"] =  value["totalpaymentReference"]  +  value2?.payment?.amount;
        });
        


        return value;
      });

      

/*
      this.installments.sort((a:any, b:any) => {
        let temp = a?.installMent?.order;
        let temp2 = b?.installMent?.order;
    
        return  temp - temp2;
      });*/
      
       
       this.view = 2;

      } catch (error) {
        console.error(error);
        
      }

      this.loading = false;

  }


  getPersonInstallmentpayment = async (installMentSelected:any) => {
       
    this.loading = true;

    this.installMentSelected = installMentSelected;
    try {
         const data =  {
          code_installMent:installMentSelected?.code 
         
         }

       const  payment  = await this._user.getPersonInstallmentpayment(data);

       this.payment_base = payment?.res;
       this.view = 3;

      } catch (error) {
        console.error(error);
        
        
      }

      this.loading = false;

  }
  

  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {
    
    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }

  closeInstallment(){

    if(this.view == 2 ){
      this.installments = [];
      this.view = 1;
      this.invoiceSelected=null;
      this.installmentNexPayment = null;

    }else  if(this.view == 3 ){
      this.view = 2;
      this.installMentSelected = null;
      this.payment_base = null;
      this.installmentNexPayment = null;
   
    }
   
         }



redirectTo(code:any) {
  this.router.navigate([`/console/payments/person/${code}`], {});
}

 public generate_csv   =  async () =>{

  this.loadingXls = true;
  try {
  const  name  = await this._user.getInstallMentsFN({f:this.date_base_string});
  this.loadingXls = false;

  
  let url = `${environment.BASE_LA}/csv/${name?.res}`;
   window.open(`${url}`);

   url = `${environment.BASE_LA}/csv/payments.xlsx`;
   window.open(`${url}`);

  } catch (error) {
    
    this.loadingXls = false;


  }

}



dateSet(e: any) {

  let date_base =  e.valueText;
  
  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;

  this.filterData = {f:this.date_base,status:'',shop:this.shop};

  this.filter();


}

filter = async () => {
  this.loading = true;
  this.search_form = '';
  const rows = await this.listInvoiceCredits();
  this.rows = rows?.res;
  this.rows_temp  =this.rows;
  this.loading = false;


}

resetFilter(){

  this.date_base = new Date();
  this.search_form = '';
  this.filterData = {f:new Date(),status:'',shop:this.shop};

}



search_form_method() {

    this.rows =[];
    this.page =  1 ;
    this.getListInvoiceCredits();

  }


    getshops = async () => {
      try {

        const resp = await this.getshopsServer();

        let shops_resp = resp?.res;
        let shops:any = {};


        shops_resp.map((value:any)=>{

          shops[value.code] = value?.name;

        });

        this.shops = shops;
        
        
      } catch (error) {
        console.error(error);

      } 

    }


    getshopsServer = async () => {
      try{
          return this._user.getshops({});

        } catch (error) {
          console.error(error);
          
          
        }
    }


    nextRows = () =>{

      
      this.page = this.page + 1 ; 
      this.getListInvoiceCredits();
    
    }
    
    birthdaySet() {  

      this.rows =[];
      this.page =  1 ; 

      this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
      this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
      this.getListInvoiceCredits();
        
  }

  resetType(){
    this.rows =[];
    this.page =  1 ; 

    this.d1 = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(new Date(), 'MM/dd/yyyy');

      if(this.type == 'f'){

        this.search_form = '';

      }
      this.getListInvoiceCredits();



  }
  
  openBottom= async (content: TemplateRef<any>,params_invoice:any) => {
    try {
      console.log(params_invoice);
  
      this.params_payment = params_invoice;
  
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
  
      });
      
  
    } catch (error) {
      console.log(error);
    }
  };

  response = (event: any) => {
 
  }
}
