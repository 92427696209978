import { Params } from './../plans/planModel';
import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';

import * as alertifyjs from 'alertifyjs';


@Component({
  selector: 'app-new-code-people',
  templateUrl: './new-code-people.component.html',
  styleUrls: ['./new-code-people.component.css']
})
export class NewCodePeopleComponent implements OnInit {

  codesList:any[] = [];
  dinamicCodeAll:any ;
  personDinamicCode:any = [];
  newPersonDinamicCode:any[] = [];


  filteredCodesList:any[] = [];
  selectedItem:string | null = null;
  openSearch:boolean = false;
  newCode:any = {code: '', name: ''};

  queryParams:any;
  person:any;
  personCode:any;

  loading:boolean = false;

  constructor(
    private _user:UserService,
    private activatedRoute:ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((params) => {
      this.queryParams = params;
      if (this.queryParams?.code) {
        this.getPerson()
      }
    });


    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      const clickedInside = this.el.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.openSearch = false;
        this.selectedItem = null;
      }
    });

 /*  const storedCodes = localStorage.getItem('personDinamicCode');
    if (storedCodes) {
        this.personDinamicCode = JSON.parse(storedCodes);
    } else {
        this.personDinamicCode = [];
    }*/

    this.getPerson()

  }

  private updateFilteredCodes(): void {
    this.filteredCodesList = this.dinamicCodeAll.filter((item:any) =>
        !this.personDinamicCode.some((personCode:any) => personCode.code === item.code)
    );
}

  async consultAllCodes(){
    try {
      if (Array.isArray(this.dinamicCodeAll)) {
        this.codesList = this.dinamicCodeAll;
        this.filteredCodesList = this.codesList;
      } else {
        this.codesList = [];
      }
    } catch (error) {
      console.error('Error en los codigos:', error);
    }
  }

  searchCode(event:Event){
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;

    this.filteredCodesList = this.codesList.filter(item =>
      item.code.toLowerCase().includes(searchTerm.toLowerCase())
    );

  }

  buttonOpen(code: string) {
    if (this.selectedItem === code) {
      this.selectedItem = null;
    } else {
      this.selectedItem = code;
    }
  }

 async getPerson(){

  this.loading=true;

  try {



    const resp = await this._user.getPersonbyCode({
      code: this.queryParams.code,
    });


    if(!this.dinamicCodeAll){

      let datas  = await this._user.getAllCodes();
      this.dinamicCodeAll = datas?.data;
  
    }


    this.consultAllCodes();


    this.person = resp.resp;
    this.personCode = this.person.person.code;



    let personDinamicCode = this.person?.person?.params?.dinamic_code  || [];

    let objCode:any = {};

    for(let item of this.dinamicCodeAll){
      objCode[item.code] = item;
    }

    if(personDinamicCode?.length > 0){

       let codes:any = [];

      for(let code of personDinamicCode){

        let item = {

               code,
               name: objCode[code]?.name
        }

        codes = [...codes,item]

      }

      this.personDinamicCode = codes || [];


    }else{
      this.personDinamicCode = [];

    }

    this.updateFilteredCodes();

    this.loading=false;

  } catch (error) {

    this.loading=false;

    console.log(error);
    
    
  }
  
  }


  async addCode(codes:any){


    let params = {
      code: codes.code,
      name: codes.name
    }

    if (params.code) {
      this._user.createCodesPeople(params, this.personCode).then(response => {
        debugger;
        alertifyjs.success(`Código agregado exitosamente`);
        this.openSearch = false;
        this.selectedItem = null;
        this.getPerson();
        this.filteredCodesList = this.filteredCodesList.filter(item => item.code !== params.code);
     //   localStorage.setItem('personDinamicCode', JSON.stringify(this.personDinamicCode))
      //  console.log(`Hola`);


      
      }).catch(error => {
        console.error('Error:', error);
        alertifyjs.error(`El código ya existe`);
      });
    }
  }

  async deleteCodePerson(code:string){
      try {
          this._user.deleteCodeForPerson(code).then(response => {
          if(response){
            alertifyjs.success(`Código eliminado exitosamente`);
            /*this.personDinamicCode = this.personDinamicCode.filter((item:any) => item.code !== code)
            const deletedCode = this.dinamicCodeAll.find((item:any) => item.code === code);
            if (deletedCode && !this.filteredCodesList.some((item:any) => item.code === deletedCode.code)) {
                this.filteredCodesList.push(deletedCode);
            }
            localStorage.setItem('personDinamicCode', JSON.stringify(this.personDinamicCode));*/

          }

          this.getPerson();

        }).catch(error => {
          console.error('Error:', error);
          alertifyjs.error(`Error al eliminar el codigo`);
        });

      } catch (error) {
        throw error;
      }
    }


  searchOpen(){
    this.openSearch = !this.openSearch;

    if(!this.openSearch){
      this.selectedItem = null;
    }
  }

}
