<div class="row">
  <div class="col-12 mt-2">
    <div class="mt-2 mb-5">
      <form
        *ngIf="!loadForm"
        autocomplete="nope"
        novalidate
        [formGroup]="form"
        class="text-start"
      >
        <div class="row">
          <div class="form-group col-12">
            <div class="form-floating">
              <select
                (change)="setBank()"
                [ngClass]="{
                  error: form.controls.bank.invalid && form.controls.bank.dirty
                }"
                class="form-select"
                id="bank"
                formControlName="bank"
                aria-label="Floating label select example"
              >
                <option value="">Selecione</option>
                <option [value]="item.code" *ngFor="let item of banks">
                  {{ item.name }}
                </option>
              </select>

              <label for="bank">Banco</label>
            </div>

            <div
              class="form-control-feedback help-block"
              *ngIf="form.get('bank').errors"
            >
              <div
                *ngIf="
                  form.get('bank').errors['required'] &&
                  !form.get('bank').pristine
                "
              >
                Campo requerido
              </div>
            </div>
          </div>

          <!-- <div class="form-group  col-12 mt-3">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.payment_method.invalid && form.controls.payment_method.dirty}" class="form-select" id="payment_method" formControlName="payment_method" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option   value="640b83e26522a425711e006f">Trasferencia</option>
                            </select>

                            <label for="payment_method">Tipo</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('payment_method').errors">
                            <div *ngIf="form.get('payment_method').errors['required'] && !form.get('payment_method').pristine">
                                Campo requerido
                            </div>
                        </div>
                    </div> -->

          <div class="form-group col-12 mt-3">
            <div class="form-floating">
              <select
                [ngClass]="{
                  error:
                    form.controls.currency.invalid &&
                    form.controls.currency.dirty
                }"
                class="form-select"
                id="currency"
                formControlName="currency"
                aria-label="Floating label select example"
              >
                <option value="">Selecione</option>
                <option value="BS">BS</option>
              </select>

              <label for="currency">Moneda</label>
            </div>

            <div
              class="form-control-feedback help-block"
              *ngIf="form.get('currency').errors"
            >
              <div
                *ngIf="
                  form.get('currency').errors['required'] &&
                  !form.get('currency').pristine
                "
              >
                Campo requerido
              </div>
            </div>
          </div>

          <div class="form-group col-12 mt-3">
            <div class="form-floating">
              <select
                [ngClass]="{
                  error:
                    form.controls.account_type.invalid &&
                    form.controls.account_type.dirty
                }"
                class="form-select"
                id="account_type"
                formControlName="account_type"
                aria-label="Floating label select example"
              >
                <option value="">Selecione</option>
                <option value="00">Corriente</option>
                <option value="01">Ahorro</option>
              </select>

              <label for="currency">Tipo de Cuenta</label>
            </div>

            <div
              class="form-control-feedback help-block"
              *ngIf="form.get('currency').errors"
            >
              <div
                *ngIf="
                  form.get('currency').errors['required'] &&
                  !form.get('currency').pristine
                "
              >
                Campo requerido
              </div>
            </div>
          </div>

          <div class="mt-3">

            <div >
              <div class="form-group">
                <div class="form-floating">

                  <input
                    (keyup)="validate_account_number()"
                    type="text"
                    [ngClass]="{
                      error:
                        form.controls.account_number.invalid &&
                        form.controls.account_number.dirty
                    }"
                    class="form-control text-capitalize"
                    id="account_number"
                    (input)="onChange($any($event))"
                    maxlength="20"
                    formControlName="account_number"
                    appNumbersOnly
                    #miInput
                  />
                  <label
                    for="account_number"
                    class="label font-weight-normal"
                    style="word-wrap: break-word"
                  >
                    <span>Número de cuenta</span>
                  </label>
                </div>

                <div
                  class="form-control-feedback help-block"
                  *ngIf="form.get('account_number').errors"
                >
                  <div
                    *ngIf="
                      form.get('account_number').errors['required'] &&
                      !form.get('account_number').pristine
                    "
                  >
                    Campo requerido
                  </div>
                </div>

                <div
                  *ngIf="
                    !validation_account_number &&
                    !form.get('account_number').pristine
                  "
                  class="form-control-feedback help-block"
                >
                  <span> Deben ser 20 dígitos </span>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <mbsc-input
              #miInput
              [options]="mobileSettingsFile"
              (change)="onFileSelected($event)"
              type="file"
              placeholder="Select file..."
              >Soporte</mbsc-input
            >
          </div>

          <br />
          <div class="form-group col-12 mt-4 mb-2">
            <div class="float-end">
              <button
                (click)="submit()"
                [disabled]="
                  !form.valid || submit_disabled || !validation_account_number
                "
                class="btn btn-block btn-primary"
              >
                <span>Agregar</span>
                <span *ngIf="submit_disabled">
                  <i class="fa fa-spinner fa-spin ms-2"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <ng-container *ngIf="loadForm || loadPerson_Address">
        <div class="w-100 text-center mt-2">
          <img src="./assets/public/images/loading.gif " alt=" " />
        </div>
      </ng-container>
    </div>

    <div class="card mt-3 mb-2" *ngFor="let item of accounts; let i = index">
      <div class="card-body">
        <div class="d-flex">
          <div>
            <div class="card-title ">
              <span class="fw-bold"> {{ item?.bank?.name }}</span>

            <p class="card-text">
              {{ item?.account_type==="00"?"Corriente": "Ahorro" }} ({{ item?.currency?.name }}),
              {{ item?.account_number }}
            </p>


            <!-- <p class="card-text" *ngIf="item?.direct_debit_default">
              <span class="small" *ngIf="item?.terms_and_condition && !item?.confirmed && !item?.code_issues"><i class="fas fa-exclamation-triangle text-warning"></i>  No verificada</span>
              <span class="small" *ngIf="item?.terms_and_condition && item?.code_issues"><i class="fas fa-exclamation-triangle text-danger"></i>  &nbsp;<span class="fw-bold">{{ item?.confirmation_issues }}</span> {{ item?.code_issues }}</span>
            </p> -->

            <div class="col-12 card text-bg-empty  p-3" *ngIf="item?.bank?.direct_debit_active && item.direct_debit">
              <figure class="mb-0 w-100 " *ngIf="!item?.afiliation && item?.terms_and_condition">
                  <i class="fas fa-house-user"></i><br>
                  <div class="font-wight-bold">No afiliado a domiciliación</div>
                  <button class="btn btn-outline-primary" (click)="createAfiliation(item)">Afiliar</button>

              </figure>

              <figure class="mb-0 w-100 " *ngIf="!item?.terms_and_condition">
                <i class="fas fa-house-user red"></i><br>
                <ng-container>
                  Estatus: esperando términos y condiciones
                </ng-container>
                <div class="font-wight-bold">
                  <span class="small">
                    <i class="fas fa-user-clock gray" title="Esperando que el cliente acepte los términos y condiciones" *ngIf="!item?.terms_and_condition"></i>  Términos y Condiciones
                  </span>
                </div>

              </figure>

              <figure class="mb-0 w-100" *ngIf="item?.afiliation && item?.terms_and_condition">
                <i class="fas fa-house-user" [class.gray]="!item?.direct_debit_default" [class.green]="item?.direct_debit_default" [title]="item?.direct_debit_default?'Domiciliación Activa': 'Activar Domiciliación'" (click)="changeDomiciliation(item)" *ngIf="item?.afiliation"></i>&nbsp;
                <i class="fas fa-unlink red" title="Desactivar Domiciliación" (click)="unlinkDomiciliation(item)" *ngIf="item?.direct_debit_default"></i> &nbsp;
                <i class="fas fa-trash red" title="Eliminar Afiliación" (click)="deleteAfiliation(item, item.afiliation)" *ngIf="item?.bank.direct_debit_afiliation_required && item?.terms_and_condition && item?.afiliation?.status === 'COMPLETE' && !item?.afiliation?.request_desafiliation_date_at && _auth.IsPermitid(['OWNER'])"></i> <br>

                <div class="font-wight-bold">
                    <ng-container >
                      Estatus: {{item?.direct_debit_default?'Activa':'Ináctiva'}}
                    </ng-container>
                    <ng-container *ngIf="item?.bank?.direct_debit_afiliation_required">
                      <br>

                      <span  *ngIf="item?.direct_debit_default"><i class="fas fa-check green" *ngIf="item?.terms_and_condition && item?.afiliation?.status === 'COMPLETE'"></i>  {{item?.terms_and_condition && item?.afiliation?.status === "COMPLETE" ? item?.afiliation?.request_desafiliation_date_at? 'Se solicitó la desafiliación':'Afiliado': (item?.afiliation?.status === "PENDING"?'Afiliación Pendiente':'')}}</span>
                    </ng-container>
                </div>

                <div class="font-wight-bold">
                  <span class="small">
                    <i class="fas fa-check green" *ngIf="item?.terms_and_condition"></i>  Términos y Condiciones
                  </span>
                </div>


                <div class="font-wight-bold">
                  <span class="small" *ngIf="item?.terms_and_condition && item?.confirmed && item?.direct_debit_default" >
                    <i class="fas fa-check green" *ngIf="item?.terms_and_condition"></i> Confirmada</span>
                </div>

                <div class="font-wight-bold" *ngIf="item?.afiliation?.status==='PENDING'">
                  <span class="small">
                    <i class="fas fa-user-clock gray" *ngIf="item?.afiliation && item?.afiliation.status==='PENDING'"></i> <i class="fas fa-check green" *ngIf="item?.afiliation && item?.afiliation.status==='COMPLETE'"></i>  Afiliación
                  </span>
                </div>
                <div class="font-wight-bold" *ngIf="item?.afiliation?.status==='ERROR'">
                  <span class="small">
                    Afiliación fallida: {{ item?.afiliation?.error }}
                  </span>
                </div>




              </figure>
            </div>




            <figure class="mb-0 w-100 " *ngIf="item?.bank?.direct_debit_active && !item.direct_debit">
              <div class="font-wight-bold">No activa la domiciliación</div>
              <button class="btn btn-outline-primary" (click)="activateAfiliation(item)">Activar</button>

            </figure>


            </div>

          </div>

          <div *ngIf="!estatus_approve || _auth.IsPermitid(['OWNER'])"  class="ms-auto text-end mt-4">
            <a
              class=" mr-4 small"
              [href]="item?.file?.url"
              target="_blank" title="Soporte"
              *ngIf="item?.file"
              ><i class="fas fa-paperclip"></i></a
            >&nbsp;
            <a
              class=" mr-4 small active-cursor"
              title="Editar"
              (click)="edit_account(item?._id)"
              *ngIf="!item?.confirmed"
              ><i class="fas fa-edit"></i></a
            >&nbsp;

            <!-- *ngIf="!item?.terms_and_condition && !item?.confirmed" -->
            <a
              class=" small"
              (click)="delete_account(item?._id)"
              title="Eliminar"
              *ngIf="!item?.direct_debit_default && _auth.IsPermitid(['OWNER'])"

              ><i class="fas fa-trash"></i></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
