<ng-template #content let-offcanvas>
    <!-- <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12  ">
                    <div class="row">
                        <h2 class="col-12 h5 mt-2 ml-3 text-2xl">
                            Nuevo Plan
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="offcanvas-body">
        <div class="container">

            <div class="row">
                <div class=" col-12 ">
                    <app-new-plan (close)="close()"></app-new-plan>
                </div>
            </div>
        </div>
    </div>
</ng-template>



<div class="mt-md-3 p-3" *ngIf="showOld">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">

            <h5 class=" text-secondary   text-center"> Planes


            </h5>
            <div class="mb-5 mt-2 text-center">
                <button (click)="pocisionBottom(content)" class="btn btn-block btn-primary ">
                <span >Nuevo plan</span>
                <!-- <span *ngIf="submit_disabled">
                  <i class="fa  fa-spinner fa-spin ms-2"></i>
                </span> -->
              </button>
            </div>

            <ng-container *ngIf="!plans_loading" class="pt-4">

                <div class="mb-2 text-start">
                    <button (click)="status_plans = true" [ngClass]="{'btn-secondary': status_plans == true,'btn-outline-secondary': status_plans == false}" class="btn btn-sm  me-3">
                       <span >Activos</span>
                    </button>

                    <button (click)="status_plans = false" [ngClass]="{'btn-outline-secondary': status_plans == true,'btn-secondary': status_plans == false}" class="btn   btn-sm ">
                        <span >Inactivos</span>
                     </button>
                </div>
                <div class=" mt-4 row">
                    <div [ngClass]="{'d-none': item.status != status_plans }" *ngFor="let item of plans" class="col-12 ">
                        <div class="card mb-4"  style="min-height: 200px;" >
                            <div class="card-body">

                            <div class="d-flex">
                                <div class="text-primary fw-bold" (click)="showPlan(item, content)">
                                    {{item?.code}}
                                </div>
                                <div class="ms-auto text-end small">
                                    <div class="cursor-pounter float-end ">
                                        <i  *ngIf="item.status == true"(click)="deletePlan(item?.code)" class="fas fa-times text-danger " style="font-size: 18px;cursor: pointer;"></i>
                                        <i  *ngIf="item.status == false"(click)="activePlan(item?.code)" class="fas fa-redo-alt text-success " style="font-size: 18px;cursor: pointer;"></i>


                                    </div>

                        <div class="me-4">
                            <span *ngIf="item?.params?.init_date">Inicio : {{item?.params?.init_date | date: 'dd/MM/yyyy'}} </span><br>
                            <span *ngIf="item?.params?.end_date"> Fin: {{item?.params?.end_date | date: 'dd/MM/yyyy'}} </span>
                        </div>
                                </div>
                            </div>
                            <div class="mt-1 ">
                                {{item?.name}} / {{item?.version?.internal_code}}
                            </div>
                            <div class="text-secondary small">
                                {{item?.description}}
                            </div>

                            <div class="mt-4">


                                <div class="fw-bold ">Paramestros </div>

                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Visibilidad: {{item?.visibility}}</span>

                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Inicial  : {{item?.initial?.status?'Si':'No'}}
                                    <span *ngIf="item?.initial?.status"> / {{item?.initial.amount}}<span>{{item?.initial?.type == "percentage"?'%':'USD'}}</span>
                                 </span>
                                </span>                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Multicompra : {{item?.params?.multiple_invoices?.status? 'Si':'No'}}
                                    <span *ngIf="item?.params?.multiple_invoices?.count"> / {{item?.params?.multiple_invoices?.count}}</span>

                                </span>                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Prioridad  : {{item?.params?.priority}}</span>

                                    <br>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount">Compra minima : {{item?.params?.min_amount}} USD</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount_base">Minimo a financiar : {{item?.params?.min_amount_base}} USD</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.max_amount_base">Maximo a financiar : {{item?.params?.max_amount_base}} USD</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_min">Cupo  minimo: {{item?.params?.current_quota_min}} USD</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_max">Cupo  maximo: {{item?.params?.current_quota_max}} USD</span>

                                <div *ngIf="item?.params?.filters?.general?.invoices?.shop || item?.params?.filters?.dinamic_code?.person">
                                    <div class="fw-bold mt-4">Filtros </div>

                                </div>

                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.general?.invoices?.shop">
                                     Tiendas {{item?.params?.filters?.general?.invoices?.shop?.type == 'white_list'?'Permitidas':'Excluidas'}}:
                                    <span *ngFor="let shop of item?.params?.filters?.general?.invoices?.shop?.list; let i=index">

                                        <span *ngIf="i>0"> ,</span>
                                        {{shop}}
                                    </span>

                                </span>


                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.dinamic_code?.person">
                                    Codigos {{item?.params?.filters?.dinamic_code?.person?.type == 'white_list'?'Permitidos':'Excluidos'}}:
                                   <span *ngFor="let item of item?.params?.filters?.dinamic_code?.person?.list; let i=index">

                                       <span *ngIf="i>0"> ,</span>
                                       {{item}}
                                   </span>

                               </span>






                                <div class="fw-bold  mt-4">Frecuencias de pago </div>
                                <ul>
                                  <li *ngFor="let term of item.payment_terms" class="small">
                                    {{ term.name }}
                                  </li>
                                </ul>

                            </div>


                           <!-- <pre>{{ item | json }}</pre>  -->

                        </div>
                      </div>
                    </div>


                </div>

                <div class="mt-2 text-secondary h5" *ngIf="!plans_active && status_plans == true">

                    No se encontraron planes activos
                </div>

                <div class="mt-2 text-secondary h5" *ngIf="!plans_inactive && status_plans == false">
                    No se encontraron planes inactivos

                </div>





            </ng-container>


            <div *ngIf="!plans_loading && !plans">
                <div>No se encontraron planes </div>
            </div>

            <ng-container *ngIf="plans_loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>


        </div>
    </div>

</div>
<app-list
[plans]="plans"
[plans_loading]="plans_loading"
[status_plans]="status_plans"
[plans_active]="plans_active"
[plans_inactive]="plans_inactive"
(newPlan)="pocisionBottom(content)"
(deletePlan)="deletePlan($event)"
(showPlanDetails)="showPlan($event, content)"
></app-list>
