<div class="div-nav">
    <nav class="navbar ak-nav-a ">
        <div class="w-100">
            <span class="navbar-header  flex gap-2">

             <img class="img-style" [src]="sanitize('../assets/public/images/banner.png')" >

             <ng-container *ngIf="isAuth && activeMenu"   >


                <span class="pe-2 d-none d-md-inline ">
                    <a  [ngClass]="{'active': menuOption == 1}" style="  text-decoration: none;" (click)="setOptionMenu(1)" [routerLink]="['/console/create-constacts']" class="nav-link" href="#" class="text-secondary pt-3 ps-4">                         <i class="  fas fa-users"></i>
                        Contactos
                    </a>
                </span>


            <span class="pe-2  d-none d-md-inline" *ngIf="_auth.IsPermitid(['OWNER','ANALIST','FINANCIAL'])">
                <a [ngClass]="{'active': menuOption == 3}"  style="  text-decoration: none;"  (click)="setOptionMenu(3)" [routerLink]="['/console/finacial-dashboard']"  class="nav-link" href="#" class="text-secondary pt-3 ps-3">                         <i class="fas fa-piggy-bank"></i>
                    Finanzas</a>
            </span>


            <span class="pe-2  d-none d-md-inline" *ngIf="_auth.IsPermitid(['OWNER','ANALIST'])">
                    <a [ngClass]="{'active': menuOption == 2}" style="  text-decoration: none;"  (click)="setOptionMenu(2)" [routerLink]="['/console/estadisticas']"  class="nav-link" href="#" class="text-secondary pt-3 ps-3">                         <i class="fas fa-tablet-alt"></i>
                        Estadísticas</a>
            </span>

            <span class="pe-2  d-none d-md-inline" *ngIf="_auth.IsPermitid(['OWNER','ANALIST'])">
                <a [ngClass]="{'active': menuOption == 5}"  style="  text-decoration: none;"  (click)="setOptionMenu(5)" [routerLink]="['/console/credit-option']"  class="nav-link" href="#" class="text-secondary pt-3 ps-3">                         <i class="fas fa-terminal"></i>
                   Consola</a>
            </span>



            </ng-container>




            </span>

            <div class="navbar-header top-nav">

                <div *ngIf="isAuth" #myDrop="ngbDropdown" ngbDropdown display="dynamic" placement="bottom-right">

                    <div style="cursor: pointer" ngbDropdownToggle id="navbarDropdown3" class=" navbar-brand-profile ak-vertical-center">

                        <!-- <div class="ak-abatar ak-dinamic-color-abatar  ">JS</div> -->
                        <div class="ak-abatar ak-dinamic-color-abatar  ">
                            <i class="fa fa-user text-primary"></i>
                        </div>
                    </div>

                    <div style="width: 270px;" ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                        <div class="col-12 pb-3  pt-2   text-center">
                            <div style="font-size:.82rem" class=" container w-100 mt-2">

                                <div class=" w-100 ">

                                   <!-- <div class="small text-secondary">Vesión {{version}}</div> --> 

                                    <div class=" ms-auto me-auto mb-2  ak-abatar ak-dinamic-color-abatar  menu">
                                        <i class="fa fa-user text-secondary fa-2x"></i>
                                    </div>

                                    <div class="ms-auto me-auto fw-bold">
                                        <div class=" fw-bold ">
                                            <span> {{sessionUser?.name}}</span>
                                            <span> {{sessionUser?.lastName}}</span>
                                        </div>

                                        <!-- <span *ngIf="socketService.statusConnet" class="text-success fz-14 me-1">Online</span>
                                        <span *ngIf="!socketService.statusConnet" class="text-danger fz-14 me-1">Offline</span> -->

                                    </div>

                                    <div class="mt-2 mb-2"> {{sessionUser?.username}}</div>
                                </div>

                                <hr>
                            </div>

                            <a href="javascript:void(0)" (click)="logout()" class="text-primary fw-bold center">
                                <span>Salir</span>
                            </a>
                            


                        </div>

                    </div>
                </div>



            </div>


        </div>
    </nav>

</div>
