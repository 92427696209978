import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { mobiscroll } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LaService } from 'src/app/modules/la/servises/la.service';



@Component({
  selector: 'ak-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe],

})
export class DashboardComponent implements OnInit {
  loading: any = true;
  isAuth: any;
  sessionUser: any;

  constructor(
    public _auth: AuthService,
    public router: Router,

  ) {



  }

  ngOnInit(): void {
//debugger

    this.sessionUser = this._auth.sessionUser;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {

        if(this.sessionUser?.role != 'COMPUTER_ROLE'){

          this.router.navigate([environment.searchClient], {});

        }else{

          this.router.navigate([environment.psicometrico], {});

        }


     //   this.getClientLaByIdentity(this._auth.sessionUser?.identity);

      }
    });




  }

/*
  getClientLaByIdentity = async (identity: any) => {
    try {

      await this._LaService.getClient(identity);
      await this._LaService.getCalendario(identity);

      const [respClient, respCalendario] = await Promise.all([
        await this._LaService.getClient(identity),
        await this._LaService.getCalendario(identity)
      ]);



        if(respCalendario?.res?.Fecha){
          localStorage.setItem("base_date",respCalendario?.res?.Fecha);
        }

      this._auth.setIdent(true);

      this.router.navigate([environment.initRoute], {});


      this.loadingdashboardLA = false;

    } catch (error: any) {

      if (error?.error?.error?.Status == 82) {

        this.notClient = true;


      } else {

        this.errorClient = true;
      }

      console.log(error?.error?.error?.Status);
      //  this._auth.setIsIdentity(false);


      this.loadingdashboardLA = false;


    }

  }
*/

  goToUrl(): void {
    this._auth.logout();
    document.location.href = 'http://dev.rv.arn.akeela.co/public/ACCOUNT_OPENING/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING?role=CLIENT';
  }

}
