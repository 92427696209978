<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2">
                <div class="col-12  col-md-8 offset-md-2">


                    <div *ngIf="view == 1" class="h4 text-dark mt-5 mb-2">
                        <span [routerLink]="['/console/invoice-dashboard']" class=" fz-16 fas fa-arrow-left me-2"> </span>

                        <span class="text-primary">{{shops && (shops[shop] || "Todas las tendas")}}</span>
                    </div>

                    <h5 class=" text-secondary text-end">
                        <button *ngIf="view != 1" (click)="closeInstallment()" type="button" class="btn-close float-start " aria-label="Close"></button>
                        <!-- <i class="  fas fa-user"></i> Compras financiadas -->
                    </h5>


                </div>
            </div>
            <ng-container>


                <div class="row rmt-2" *ngIf="view == 1">
                    <div class="col-12  col-md-8 offset-md-2">
                        <div *ngIf="loading_stadistic" class="text-secondary w-100  mt-5 ">
                            Cargando estadisticas ...
                        </div>

                        <div class="mt-4 text-start row mb-5 ">

                            <div class=" col-12 col-md-5 mt-2">
                                <div class="form-floating ">
                                    <select (change)="resetType()" [(ngModel)]="type" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                                        <option   value="f">Fecha</option>
                                        <option   value="n"># Crédito</option>
                                    
                                          </select>
                                    <label for="floatingSelect ">Filtrar por</label>
                                </div>

                            </div>



                            <div class="col-12  col-md-7  mt-2" *ngIf="type == 'f'">
                                <input style="
                                padding: 16px 9px;
                                background: white;
                            " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting3" #externalRange="mobiscroll" />

                            </div>

                            <div class="col-12  col-md-7" *ngIf="type == 'n'">
                                <div class="input-group ">

                                    <div class="form-floating ">
                                        <input type="text" [(ngModel)]="search_form" class="form-control " id="contact" placeholder="name@example.com">
                                        <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span > credito-#
                                        </span>
                                    </label>
                                    </div>
                                    <button (click)="search_form_method()" [disabled]=" loading" class="btn btn-primary">
                                        Buscar
                                        <span *ngIf="loading">
                                        <i class="fa  fa-spinner fa-spin"></i>
                                        </span> 
                                     </button>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="!loading_stadistic  && type == 'f'" class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">
                            <ng-container>


                                <div class=" d-flex text-dark pt-3" (click)="filterData.status = 'p'; filter()">
                                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning"> Pendientes
                                            </span>

                                            <span>
                                     {{stadistic?.pending}} 
                                    </span>
                                        </div>

                                    </div>
                                </div>


                                <div class=" d-flex text-dark pt-3 " (click)="filterData.status = 'c'; filter()">
                                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                        <div class="d-flex justify-content-between">
                                            <span class=""> Completados
                                            </span>

                                            <span>
                                     {{stadistic?.complete}} 
                                    </span>
                                        </div>

                                    </div>
                                </div>



                                <div class=" d-flex text-dark pt-3" (click)="filterData.status = 't'; filter()">
                                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-secondary"> Borrador
                                            </span>

                                            <span>
                                     {{stadistic?.trash}} 
                                    </span>
                                        </div>

                                    </div>
                                </div>



                                <div class=" d-flex text-dark pt-3" (click)="filterData.status = 'all'; filter()">
                                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                        <div class="d-flex justify-content-between">
                                            <span class=""> Total
                                            </span>

                                            <span class="fw-bold ">
                                     {{stadistic?.trash + stadistic?.pending + stadistic?.complete}} 
                                    </span>
                                        </div>

                                    </div>
                                </div>



                            </ng-container>
                            <!-- <pre>
                        {{ stadistic | json }}
                    </pre> -->
                        </div>




                        <div *ngIf="!loading" class=" w-100  mt-5 mb-2 flex ">

                            <div class="float-start me-2" *ngIf="filterData?.status != ''">
                                <button type="button" (click)="resetFilter();filter()" class="btn-close" aria-label="Close"></button>

                            </div>

                            <div class="fw-bold" *ngIf="filterData?.status == 'c'">
                                Completados
                            </div>
                            <div class="fw-bold" *ngIf="filterData?.status == 'p'">
                                Activas
                            </div>
                            <!-- <div class="fw-bold" *ngIf="filterData?.status == 't'">
                                Borrador
                            </div> -->

                            <div class="fw-bold" *ngIf="filterData?.status == 'all'">
                                Total
                            </div>


                        </div>


                        <ng-container>

                            <div class="text-end ml-auto" *ngIf="!loading && rows?.length > 1">
                                {{rows?.length}}
                            </div>
                        </ng-container>

                        <ng-container>


                            <div class="mt-3  p-3 bg-body rounded shadow-sm" *ngFor="let item of rows">
                                <div class="row mt-2  row-k">
                                    <!-- <pre>{{ item | json }}</pre> -->

                                    <div class="col-12">
                                        <div class="d-flex" [routerLink]="['/console/person/financial-payments/',item?.customer?.code]">

                                            <div>
                                                <div *ngIf="item?.customer?.file_selfie">
                                                    <img style="width: 32px; height: 32px;" class="bd-placeholder-img flex-shrink-0 me-2 rounded " [src]="sanitize(item?.customer?.file_selfie?.url)">
                                                </div>

                                                <div *ngIf="!item?.customer?.file_selfie">
                                                    <img *ngIf="item?.customer.gender != 'F'" style="width: 32px; height: 32px;" class="bd-placeholder-img flex-shrink-0 me-2 rounded " [src]="sanitize('../assets/public/images/avatar.png')">
                                                    <img *ngIf="item?.customer.gender == 'F'" style="width: 32px; height: 32px;" class="bd-placeholder-img flex-shrink-0 me-2 rounded " [src]="sanitize('../assets/public/images/avatara-female.png')">
                                                </div>
                                            </div>
                                            <div>
                                                <strong class="text-gray-dark text-capitalize">{{item?.customer?.name}} {{item?.customer?.lastName}}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="small text-secondary">Compra financiada total</div>
                                        <h5 class="fw-bolder text-dark"> {{item?.totalAmount | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></h5>
                                    </div>
                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Fecha de aprobación</div>
                                        <div class=" text-dark"> {{item?.dueDate | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                    </div>

                                    <div class="col-6 ">
                                        <div class="small text-secondary">ID transacción</div>
                                        <div class=" text-dark"> {{item?.identifier }}</div>
                                    </div>


                                    <div class="col-6 text-end ">
                                        <div class="small text-secondary ">ID documento</div>
                                        <div class=" text-dark"> {{item?.merchantNumber }}</div>
                                    </div>


                                    <div class="col-6 ">
                                        <div class="small text-secondary">Tienda</div>
                                        <div class=" text-dark"> {{shops[item?.provider_transaction?.shop]}}</div>
                                    </div>


                                    <div class="col-6 text-end ">
                                        <div class="small text-secondary ">Caja</div>
                                        <div class=" text-dark"> {{item?.provider_transaction?.cash_register }}</div>
                                    </div>

                                    <div class="col-12 ">
                                        <div class="small text-secondary ">C.I. Cajero</div>
                                        <div class=" text-dark"> {{item?.provider_transaction?.user }}</div>
                                    </div>


                                    <div class="col-6">
                                        <div class="small text-secondary">Plan</div>
                                        <div class="text-dark">{{item?.plan?.name}} </div>
                                    </div>
                                    <div class="col-6  text-end ">
                                        <div class="small text-secondary">Estatus</div>
                                        <div [ngClass]="{
                                        'text-success': item?.payment_status?.code == 'PaymentComplete',
                                        'text-dark': item?.payment_status?.code == 'PaymentPending',
                                        'text-warning': item?.payment_status?.code == 'PaymentTrash'
                                    }" class="text-dark" [title]="item?.installMent ?.payment_status?.description">{{item?.payment_status?.name}} </div>
                                    </div>


                                    <!-- <div class="col-12" (click)="getPersonInstallmentsInvoiceCredits(item)"> -->

                                    <div class="col-12" (click)="getPersonInstallmentsInvoiceCredits(item)">

                                        <div class="small text-secondary">Cuotas </div>
                                        <div>{{item?.plan?.number_installment}}

                                            <a class="text-primary cursor-pounter float-end">Ver cuotas</a></div>
                                    </div>
                                    <div class="col-6 ">
                                        <div class="small text-secondary">Monto inicial</div>
                                        <div class=" text-dark">{{item?.minimumPaymentDue | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                                    </div>

                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Monto financiado</div>
                                        <div class=" text-dark">{{item?.amount | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>

                                    </div>

                                    <div class="col-6 ">
                                        <div class="small text-secondary">Monto pendiente</div>
                                        <div class=" text-dark">{{item?.dueBalance | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>

                                    </div>

                                </div>

                            </div>
                        </ng-container>
                    </div>

                </div>

                <div class="row rmt-2" *ngIf="view == 2">
                    <div class="col-12  col-md-8 offset-md-2">
                        <div *ngIf="installments && view == 2" class="text-center">
                            <div class="text-secondary">---</div>

                            <div class="small text-secondary mt-2">ID Transaccion</div>
                            <div class=" text-primary"> {{invoiceSelected?.identifier }}</div>
                            <div class="small text-secondary mt-2">Total</div>
                            <div class=" text-dark">{{invoiceSelected?.totalAmount | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                            <div class="small text-secondary mt-2">Monto pendiente</div>
                            <div class=" text-dark">{{invoiceSelected?.dueBalance | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                            <div class="mt-2 fw-bold">Cuotas {{installments?.length}}</div>

                        </div>


                        <div *ngFor=" let item of installments;let i = index" class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm">

                            <div class="row mt-2 row-k">
                                <div class="col-12 mt-2">

                                    <h5 class="fw-bolder text-dark"> {{ item?.installMent ?.order
                                        < 1 ? 'Inicial': 'cuota '+item?.installMent ?.order}}</h5>
                                </div>
                                <div class="col-6">
                                    <div class="small text-secondary">Total</div>
                                    <div class=" text-dark"> {{item?.installMent ?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Estatus</div>
                                    <div [ngClass]="{'text-success': item?.installMent ?.payment_status?.code == 'PaymentComplete','text-dark': item?.installMent ?.payment_status?.code != 'PaymentComplete'}" class="text-dark" [title]="item?.installMent ?.payment_status?.description">{{item?.installMent ?.payment_status?.name}} </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="small text-secondary">Fecha</div>
                                    <div class=" text-dark"> {{item?.installMent ?.date | date: 'dd/MM/yyyy'}}</div>

                                </div>

                                <div class="col-12 ">
                                    <a *ngIf="item?.installMent ?.payment_status?.code == 'PaymentComplete'" (click)="getPersonInstallmentpayment(item?.installMent )" class="text-primary cursor-pounter float-end">Ver pagos</a>

                                    <div class="small text-secondary">Fecha de Pago</div>
                                    <div class=" text-dark"> {{item?.installMent ?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}</div>
                                </div>
                                <div class="col-6 ">
                                    <div class="small text-secondary">Pagada</div>
                                    <div class=" text-dark"> {{ (item?.installMent ?.amount - item?.installMent ?.dueBalance ) | currency:'USD':'': '1.2-2'}} USD</div>
                                </div>

                                <!-- <div class="col-6 text-end">
                                    <div class="small text-secondary">Restante</div>
                                    <div class=" text-dark"> {{item?.installMent ?.dueBalance | currency:'USD':'': '1.2-2'}} USD</div>
                                </div> -->

                                <div class="col-6 text-end" *ngIf="item?.totalpaymentReference  > 0">
                                    <div class="small text-secondary">Total (Por conciliar)
                                    </div>
                                    <div class=" text-dark"> {{item?.totalpaymentReference | currency:'USD':'': '1.2-2'}} USD</div>
                                </div>

                                <!-- <div class="col-6  text-end" *ngIf="item?.totalpaymentReference > item?.installMent ?.amount">
                                    <div class="small text-secondary"> Total exedente (Por conciliar ) </div>
                                    <div class=" text-dark"> {{ (item?.totalpaymentReference - item?.installMent ?.dueBalance) | currency:'USD':'': '1.2-2'}} USD</div>
                                </div> -->

                            </div>
                            
                            <div class="row mt-2 row-k" *ngIf="item?.payments?.length > 0 || item?.paymentReference?.length > 0">

                                <div class="fw-bold text-secondary">Pagosss</div>

                                <ng-container class="mt-3" *ngFor=" let item2 of item?.payments ;let i = index">

                                    <div class="col-6 ">
                                        <div class="small ">

                                            {{ item2 | json }}
                                            <span>
                                               {{item2?.payment?.paymentMethodId}}
                                                <div class="text-primary small cursor-pointer" (click)="openBottom(paymentcontainer,{ person_code: item?.installMent?.invoice?.customer?.code, code_payment: item2?.payment?.code})">  {{item2?.payment?.code}}</div>
                                                
                                               
                                          

                                            </span>

                                        </div>

                                    </div>
                                    <div class="col-6  text-end small">
                                        <div class=" text-dark "> {{item2?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                        <div class="text-success" *ngIf="item2?.payment?.reconciled_payment"><span class="badge text-bg-success">Aprobado</span> </div>

                                        <div *ngIf="!item2?.payment?.reconciled_payment"><span (click)="redirectTo(item?.customer?.code)" class="badge text-bg-warning">Por conciliar</span>
                                        </div>
                                    </div>

                                </ng-container>

                                <ng-container class="mt-3" *ngFor=" let item2 of item?.paymentReference ;let i = index">

                                    <div class="col-6 ">
                                        <div class="small ">

                                            <span>
                                               {{item2?.payment?.paymentMethodId}}
                                            <div class=" text-secondary small">  {{item2?.payment?.code}}</div>
                                          

                                            </span>

                                        </div>

                                    </div>
                                    <div class="col-6  text-end small">
                                        <div class=" text-dark "> {{item2?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                        <div class="text-success" *ngIf="item2?.payment?.reconciled_payment">Conciliado </div>
                                        <div *ngIf="!item2?.payment?.reconciled_payment"><span (click)="redirectTo(item?.customer?.code)" class="badge text-bg-warning">Por conciliar</span>
                                        </div>
                                    </div>

                                </ng-container>


                            </div>

                            <!-- installmentNexPayment == item?.installMent?.code && -->
                            <div *ngIf=" (( item?.installMent ?.dueBalance - item?.totalpaymentReference ) > 0) &&  invoiceSelected?.payment_status?.code != 'PaymentTrash' " class="col-12 text-end mt-3">
                                <span class="badge text-bg-warning">{{ (item?.installMent ?.dueBalance - item?.totalpaymentReference) | currency:'USD':'': '1.2-2'}} USD faltantes</span> 

                            </div>

                        </div>


                    </div>

                </div>

                <div class="row rmt-2" *ngIf="view == 3">
                    <div class="col-12  col-md-8 offset-md-2">
                        <div *ngIf="installments" class="text-center">
                            <div class="text-secondary">---</div>
                            <div class="small text-secondary mt-2">ID Transaccion</div>
                            <div class=" text-primary"> {{invoiceSelected?.identifier }}</div>

                            <div class="fw-bold"> Pago {{ installMentSelected?.order
                                < 1 ? 'Inicial': 'Cuota '+ installMentSelected?.order }} </div>

                            </div>



                            <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" *ngFor="let payment of payment_base">

                                <div class="row mt-2 row-k">
                                    <div class="col-6 mt-2">
                                        <h5 class="fw-bolder text-dark"> Pago</h5>
                                    </div>
                                    <div class="col-8 mt-2  ">
                                        <div class="small text-secondary">Código</div>
                                        <div class=" text-dark small"> {{payment?.payment?.code}}</div>
                                    </div>
                                    <div class="col-4   ">

                                        <div class="text-end ms-auto">
                                            <div class="small text-secondary">Conciliación</div>

                                            <div class=" small text-success" [ngClass]="{'text-success': payment?.payment?.typeTransaction == 'AUTO','text-secondary': payment?.payment?.typeTransaction != 'AUTO'}"> {{payment?.payment?.typeTransaction == 'AUTO'? 'Auto':'Manual'}}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="small text-secondary">Monto total</div>
                                        <div class=" text-dark"> {{payment?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>
                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Estatus</div>
                                        <div class="text-success">Pago conciliado </div>
                                    </div>
                                    <div class="col-12 ">
                                        <div class="small text-secondary">Fecha</div>
                                        <div class=" text-dark"> {{payment?.payment?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                    </div>



                                    <div class="col-6 ">
                                        <div class="small text-secondary">Forma de Pago</div>
                                        <div class=" text-dark" [title]="payment?.payment?.payment_method?.description"> {{payment?.payment?.payment_method?.name}}</div>

                                    </div>
                                    <div class="col-6  text-end">
                                        <div class="small text-secondary">Número de confirmación</div>
                                        <div class=" text-dark"> {{payment?.payment?.paymentMethodId}}</div>
                                    </div>

                                </div>

                                <div class="fw-bold text-secondary mt-3 mb-2" *ngIf="payment?.payment?.responseAutoConfirm?.voucher">Voucher</div>


                                <div class="text-dark  fw-bold fz-14 mb-2" *ngIf="payment?.payment?.responseAutoConfirm?.descripcion">
                                    {{payment?.payment?.responseAutoConfirm?.descripcion}}
                                </div>

                                <div class="row" *ngIf="payment?.payment?.responseAutoConfirm?.voucher">
                                    <div class="col-12 col-md-6">
                                        <div class="voucher p-2 text-dark text-uppercase" [innerHTML]="payment?.payment?.responseAutoConfirm?.voucher">

                                        </div>

                                    </div>
                                </div>


                                <div class="row mt-3 row-k" *ngIf="payment?.installMentsPayment?.length > 0">


                                    <div class="fw-bold text-secondary col-12 mt-2">Distribución</div>
                                    <ng-container *ngFor=" let item of payment?.installMentsPayment ;let i = index">
                                        <div class="col-8">
                                            <div class="small text-primary">{{ item?.installMent?.order
                                                < 1 ? 'Inicial': 'Cuota '+ item?.installMent?.order }} </div>
                                                    <div class="small text-secondary small">{{item?.installMent?.code }}</div>

                                            </div>
                                            <div class="col-4 text-end ">
                                                <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                            </div>

                                    </ng-container>

                                    </div>

                                </div>


                            </div>

                        </div>


            </ng-container>

            <div *ngIf="view == 1 && !loading && rows?.length > 1" class="mt-4 text-center">

                <button type="button " class="btn btn-primary primary  " (click)="nextRows()">Siguientes + </button>

            </div>

            <div *ngIf="!loading && rows?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron registros </div>
                </div>
            </div>


            <ng-container *ngIf="loading">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif" alt=" ">
                </div>
            </ng-container>
            </div>
            </div>

        </div>






        <ng-template #paymentcontainer let-offcanvas>
            <div class="offcanvas-header">
                <div class="container ">
                    <div class="row">
                        <div class="col-12 col-md-10 offset-md-1 ">
                            <div class="row">
                                <div class="col-10">
                                    <div class="text-capitalize h5 text-secondary mt-2 ">
                                       Detalle de Pago
                                    </div>
                                </div>
                                <div class="col-2 text-end pt-2">
                                    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="offcanvas-body">
                <div class=" container">
                    <div class="row">
                        <div class=" col-12 col-md-10 offset-md-1">
                            <div class="text-start">
                                <app-person-payments [code_person]="params_payment['person_code']" [code_payment]="params_payment['code_payment']" (resetStatus)="response($event)"></app-person-payments>
          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </ng-template>