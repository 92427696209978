import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plan } from './planModel';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  selectedPlan: any = null;
  base = `${environment.BASE_LA}/api`;
  constructor(public httpClient: HttpClient) {}

  createPlan(plan: Plan) {
    return this.httpClient.post(`${this.base}/plans`, plan);
  }


  updatePlan = async (body:any) => {
    const headers = { 'content-type': 'application/json' };

    debugger
    try {
      const response = await lastValueFrom(
        this.httpClient.put<any>(
          `${this.base}/plans`,
          body,
          { headers }
        )
      );
      return response;
    } catch (error) {
      console.error('Error updating default plan:', error);

      // Re-throw the error or return a rejected promise so it propagates correctly
      return Promise.reject(error);
    }
  };



  getAllPlans(plan: Plan) {
    return this.httpClient.get(`${this.base}/plans/all`);
  }

  getPlanByCode(code: string) {
    let params = new HttpParams().set('code_plan', code);
    return this.httpClient.get(`${this.base}/plans`, { params});
  }

  getCodes() {
    return this.httpClient.get(`${this.base}/code`);
  }
}
