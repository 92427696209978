import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inactive-quota',
  templateUrl: './inactive-quota.component.html',
  styleUrls: ['./inactive-quota.component.css']
})
export class InactiveQuotaComponent implements OnInit {
  loadingXls:any;
  type:any = 'INACTIVAR_CUPOS_MASIVAMENTE';
  fileName = '';
  formDataUpload:any = null;
  trace_documets:any = [];
  loadingXls2:any;


  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    public socketService: SocketioService
  ) { }

  ngOnInit(): void {
    this.getTrace_documets('INACTIVAR_CUPOS_MASIVAMENTE');
  }

  reload_noti(){
    this.getTrace_documets('INACTIVAR_CUPOS_MASIVAMENTE');
  }

  onFileSelected  = async  (event:any) => {
    debugger
    const file:File = event.target.files[0];
    if (file) {
        this.fileName = file.name;
        this.formDataUpload = file;
    }
  }


  submit = async () => {
    debugger
    this.loadingXls = true;
    try {
      const formData = new FormData();
      if (this.formDataUpload) {
       formData.append("file", this.formDataUpload);
      }

      let resp = await this._user.bulk_cupo_inactivate(formData);
      this.reload_noti();

      alertifyjs.success(`Solicitud para inactivar masivamente los cupos de clientes exitosa`);
      this.loadingXls = false;
    } catch (error:any) {
      if(error.status == 400 ){
        alertifyjs.error(error.error.msg);
      }else{
        alertifyjs.error(`Error al inactivar masivamente los cupos de clientes`);
      }
      this.reload_noti();

    }
    this.loadingXls = false;

  }


  public getTrace_documets  = async (type:any) =>{
    try {
      try {

        let result = await this._user.traceDocumentsGet({type});

        this.trace_documets = result.resp;
      } catch (error) {
        console.log(error);


      }

    } catch (error) {
      this.loadingXls2 = false;
    }

  }



  dowloader_document(url:any){
    
    let url_f = `${environment.BASE_LA}/${url}`;
    window.open(`${url_f}`);
  }


}
